<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <!-- <div class="webIcon" @click="toWeb"></div> -->
      <div class="telegram" v-if="cTg" @click="jumpUrl(cTg)"></div>
      <div class="potato" v-if="cPotato" @click="jumpUrl(cPotato)"></div>
      <div class="business" v-if="cBusiness" @click="jumpUrl(cBusiness)"></div>
      <div class="channel" v-if="cChannel" @click="jumpUrl(cChannel)"></div>
    </div>
    <div class="figure"></div>
    <div class="logoText"></div>
    <div class="bottomTip"></div>
    <div class="downloadBox">
      <img class="qrcodeBox" :src="qrImg" alt="" />
      <div class="btnBox">
        <div class="iosBtn"></div>
        <div class="androidBtn"></div>
      </div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  data() {
    return {
      cTg: "",
      cPotato: "",
      cBusiness: "",
      cChannel: "",
    };
  },
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toWeb() {
      window.open("https://emcmqw.com");
    },
    setConfigs(configs) {
      configs.forEach(item => {
        if (item.configType === "tg_group") {
          this.cTg = item.link;
        } else if (item.configType === "potato_group") {
          this.cPotato = item.link;
        } else if (item.configType === "sw_cooperate") {
          this.cBusiness = item.link;
        } else if (item.configType === "qd_cooperate") {
          this.cChannel = item.link;
        }
      });
    },
    jumpUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    height: 103px;
    width: 368px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 54px;
    left: 81px;
  }
  .contactBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 61px;
    top: 49px;
    .webIcon {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/webIcon.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .business {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/icon-business.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .channel {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/icon-channel.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .figure {
    height: 869px;
    width: 837px;
    background: url("./../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 150px;
    top: 143;
  }
  .logoText {
    height: 277px;
    width: 715px;
    background: url("./../../../assets/images/pc/logoText.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 102px;
    top: 274px;
  }
  .bottomTip {
    height: 22px;
    width: 656px;
    background: url("./../../../assets/images/pc/bottomTip.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 164px;
    bottom: 360px;
  }
  .downloadBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 164px;
    bottom: 100px;
    .qrcodeBox {
      height: 248px;
      width: 248px;
      background-color: white;
      border-radius: 10px;
      margin-right: 50px;
    }
    .btnBox {
      .iosBtn {
        height: 88px;
        width: 398px;
        background: url("./../../../assets/images/pc/iosBtn.png") no-repeat;
        background-size: 100% 100%;
      }
      .androidBtn {
        height: 88px;
        width: 398px;
        background: url("./../../../assets/images/pc/androidBtn.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 36px;
      }
    }
  }
}
</style>
