<template>
  <div class="mobilePage">
    <div class="header">
      <div class="logo"></div>
      <div class="contactOfficialBox">
        <!-- <div class="webIcon" @click="toWeb"></div> -->
        <!-- <div class="contactOfficial" @click="toTg"></div> -->
      </div>
    </div>
    <div class="figure"></div>
    <div class="boxSize"></div>
    <div class="floatingBox" v-if="isShowContactBox">
      <div class="telegram" v-if="cTg" @click="jumpUrl(cTg)"></div>
      <div class="potato" v-if="cPotato" @click="jumpUrl(cPotato)"></div>
      <div class="business" v-if="cBusiness" @click="jumpUrl(cBusiness)"></div>
      <div class="channel" v-if="cChannel" @click="jumpUrl(cChannel)"></div>
    </div>
    <div class="bottomBox">
      <div
        class="downloadBox"
        v-if="os.isPhone"
        @click="getApkInfo('ios')"
      ></div>
      <div class="downloadBox" v-else @click="getApkInfo('android')"></div>
      <div :class="os.isPhone ? 'safariTip' : 'bottomTip'"></div>
    </div>
    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="androidPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-popup v-model="iosPromptShow" position="left" class="iosOverlay">
      <div class="iosHeader">
        <div class="iosClose" @click="iosPromptShow = false"></div>
        iOS安装教程
      </div>
      <div class="iosMain">
        <div class="iosInstall"></div>
      </div>
    </van-popup>
    <!-- <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay> -->

    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">
        请使用Safari浏览器进行下载
      </div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href,
      cTg: "",
      cPotato: "",
      cBusiness: "",
      cChannel: "",
      isShowContactBox: false
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    // alert(ua)
    console.log(ua);
    console.log(isSafari);
    // console.log(ua.indexOf('safari'), "------");
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
      // console.log(this.showTip, '-------------')
      // alert('请使用Safari浏览器进行下载')
    }
    const referrer = sessionStorage.getItem("referrer");
    const dc = sessionStorage.getItem("dc");
    if (referrer && dc) {
      this.currUrl = referrer + "user.html?dc=" + dc;
    } else {
      if (referrer) {
        this.currUrl = referrer + "user.html";
      } else {
        this.currUrl = window.location.href;
      }
    }
  },
  methods: {
    // 剪贴板
    doCopy() {
      this.$copyText(this.currUrl).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo(type) {
      await this.showInstallTutorial(type);
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    // toWeb() {
    //   window.open("https://emcmqw.com");
    // },
    // toTg() {
    //   window.open("https://t.me/haijiaoHQ");
    // },

    setConfigs(configs) {
      if (!configs.length) return;
      this.isShowContactBox = true;
      configs.forEach(item => {
        if (item.configType === "tg_group") {
          this.cTg = item.link;
        } else if (item.configType === "potato_group") {
          this.cPotato = item.link;
        } else if (item.configType === "sw_cooperate") {
          this.cBusiness = item.link;
        } else if (item.configType === "qd_cooperate") {
          this.cChannel = item.link;
        }
      });
    },
    jumpUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobilePage {
  height: 100%;
  width: 100%;
  background-color: #061511;
  overflow-y: auto;
  .header {
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #061511;
    padding: 0 18px;
    position: fixed;
    left: 0;
    top: 0;
    .logo {
      height: 40px;
      width: 150px;
      background: url("./../../../assets/images/mobile1/logo.png") no-repeat;
      background-size: 100% 100%;
    }
    .contactOfficialBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .webIcon {
        height: 25px;
        width: 52px;
        background: url("./../../../assets/images/mobile1/webIcon.png")
          no-repeat;
        background-size: 100% 100%;
        margin-right: 12px;
      }
      .contactOfficial {
        height: 26px;
        width: 84px;
        background: url("./../../../assets/images/mobile1/contactOfficialBg.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .figure {
    height: 812px;
    width: 100%;
    background: url("./../../../assets/images/mobile1/figure.webp") no-repeat;
    background-position: 0 -40px;
    background-size: 100% 100%;
  }
  // .boxSize {
  //   height: 63px;
  // }
  .floatingBox {
    border-radius: 28px;
    border: 1px solid #fff;
    background: #2d2c2f;
    width: 55px;
    position: fixed;
    right: 0;
    top: 187px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 30px 0;
    .telegram {
      height: 42px;
      width: 42px;
      background: url("./../../../assets/images/mobile1/telegram.png") no-repeat;
      background-size: 100% 100%;
    }
    .potato {
      height: 42px;
      width: 42px;
      background: url("./../../../assets/images/mobile1/potato.png") no-repeat;
      background-size: 100% 100%;
    }
    .business {
      height: 42px;
      width: 42px;
      background: url("./../../../assets/images/mobile1/business.png") no-repeat;
      background-size: 100% 100%;
    }
    .channel {
      height: 42px;
      width: 42px;
      background: url("./../../../assets/images/mobile1/channel.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .bottomBox {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 13px 0 10px;
    border-radius: 10px 10px 0 0;
    .bottomTip {
      height: 12px;
      width: 252px;
      background: url("./../../../assets/images/mobile1/bottomTip.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .safariTip {
      height: 12px;
      width: 183px;
      background: url("./../../../assets/images/mobile1/safariTip.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .downloadBox {
      height: 42px;
      width: 133px;
      background: url("./../../../assets/images/mobile1/downloadBtn.png")
        no-repeat;
      background-size: 100% 100%;
      margin: 3px auto 13px;
    }
  }
  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/android_tip.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
  .iosOverlay {
    height: 100%;
    width: 100%;
    background-color: white;
    .iosHeader {
      height: 44px;
      position: relative;
      text-align: center;
      line-height: 44px;
      font-size: 18px;
      color: rgb(51, 51, 51);
      .iosClose {
        position: absolute;
        left: 16px;
        top: 10px;
        height: 24px;
        width: 24px;
        background: url("./../../../assets/images/mobile1/back.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .iosMain {
      height: calc(100% - 44px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      .iosInstall {
        height: 1679px;
        width: 100%;
        background: url("./../../../assets/images/mobile1/iosInstall.webp")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;
  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }
  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }
  .url {
    font-size: 15px;
    margin-top: 16px;
    text-align: left;
  }
  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
